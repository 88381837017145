import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { FlexRow, FlexColumn } from "./global/Wrappers"
import { mobileSize, laptopSize, desktopSize } from "../../utils";

interface imagePositionProp {
    imagePosition: string;
}

const InnerWrapper = styled.div<imagePositionProp>`
    margin-bottom: 10rem;
    padding: ${props => props.imagePosition === `right` ? `0 0 0 2.5rem` : `0 2.5rem 0 0` };
    
    @media ${desktopSize} {
        margin-bottom: 10rem;
    }

    @media ${mobileSize} {
        padding: 0;
    }
`

/**
 * Props and types
 * imagePosition: string - `right`, `left`, null, false
 * */
const ImageWrapper = styled.div<imagePositionProp>`
    @media ${laptopSize} {
        margin: ${props => props.imagePosition === `right` ? `0 -1.25rem 2rem 0` : `0 0 2rem -1.25rem` };
    }
`

/**
 * Props and Types
 *  link: string
 *  image: string
 *  heading: string
 *  subheading: string
 *  body: array
 * */

interface Props {
    image: string;
    imagePosition: string;
}

const TwoColumnImageText: React.FC<Props> = ({ image, imagePosition, children }) => {
    const data = useStaticQuery(graphql`
        query {
            allFile {
                edges {
                    node {
                        relativePath
                        childImageSharp {
                            gatsbyImageData (quality: 99)
                        }
                        publicURL
                    }    
                }
            }
        }
    `)

    const filtered = data.allFile.edges.filter(edge => edge.node.relativePath === image)
    const imageData = filtered[0].node.childImageSharp !== null ? filtered[0].node.childImageSharp.gatsbyImageData : filtered[0].node.publicURL

    let flexDirection = `row`

    if (imagePosition === `right`) {
        flexDirection = `row-reverse`
    }

    return(
        <InnerWrapper imagePosition={imagePosition}>
            <FlexRow startFlexOn={`tablet`} flexDirection={flexDirection}>
                <FlexColumn startFlexOn={`tablet`} width={`45%`}>
                    {imageData && image && 
                        <ImageWrapper imagePosition={imagePosition}>
                            <GatsbyImage image={imageData} objectFit={`contain`} alt="" />  
                        </ImageWrapper>
                    }
                </FlexColumn>
                <FlexColumn startFlexOn={`tablet`} width={`55%`}>
                    {children}
                </FlexColumn>
            </FlexRow>
        </InnerWrapper>
    )
}

export default TwoColumnImageText