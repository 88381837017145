import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { theme } from '../../../utils/theme';
import { H2, IntroPara } from '../global/Text';
import { Section, SlimContainer } from '../global/Wrappers';
import TwoColumnImageText from '../TwoColumnImageText';

const IntroWrapper = styled.div`
  margin-bottom: 3.4375rem;
`;

const BragEyebrow = styled.span`
  display: block;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 3.2rem;
  margin: 0;
  color: #67787d;
`;

const BragText = styled.span`
    display: block;
    font-family: ${theme.PRIMARY_FONT};
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.2rem;
    margin: 0 0 1.5rem;
    color: ${theme.MAIN_DARK_BLUE};
`;

const ArrowLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 1.5;
  margin: 0;
  color: ${theme.MAIN_DARK_BLUE};
  text-decoration: none;
  transition: color 0.15s ease-out;

  svg {
    margin-left: 0.4rem;
  }

  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
`;

/**
 * Props and Types
 *  heading: string
 *  body: array
 *  items: array: obj: image: string, heading: string, link: string
 * */
interface WorkProps {
  heading: string;
  body: string[];
  items: {
    logo: string;
    image: string;
    imagePosition: string;
    heading: string;
    body: string[];
    pageLink: string;
    brag: {
      eyebrow: string;
      text: string;
    };
  }[];
}

const Work: React.FC<WorkProps> = ({ heading, body, items }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 95)
            }
            publicURL
          }
        }
      }
    }
  `);
  return (
    <Section>
      <SlimContainer>
        <IntroWrapper>
          <H2 weight={800} margin={`0 0 1rem`}>
            {heading}
          </H2>
          <div>
            {body.map((paragraph, index) => {
              return <IntroPara margin={`0 0 1.5rem`}>{paragraph}</IntroPara>;
            })}
          </div>
        </IntroWrapper>
        <div>
          {items.map((item, index) => {
            const filtered = data.allFile.edges.filter(
              (edge) => edge.node.relativePath == item.logo,
            );
            const logo =
              filtered[0].node.childImageSharp !== null
                ? filtered[0].node.childImageSharp.gatsbyImageData
                : filtered[0].node.publicURL;

            return (
              <TwoColumnImageText image={item.image} imagePosition={item.imagePosition}>
                <div>
                  <GatsbyImage image={logo} objectFit={`contain`} alt="logo" />
                </div>
                <H2 weight={700} margin={`1.8125rem 0 0.75rem`}>
                  {item.heading}
                </H2>
                <div>
                  {item.body.map((paragraph, index) => {
                    return <IntroPara margin={`0 0 2.5rem`}>{paragraph}</IntroPara>;
                  })}
                </div>
                <p>
                  <BragEyebrow>{item.brag.eyebrow}</BragEyebrow>
                  <BragText>{item.brag.text}</BragText>
                </p>
                {item.pageLink && (
                  <div>
                    <ArrowLink to={item.pageLink}>
                      Project Details
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.4248 1.09375C6.1123 1.40625 6.14355 1.875 6.4248 2.1875L10.2061 5.75H1.2373C0.799805 5.75 0.487305 6.09375 0.487305 6.5V7.5C0.487305 7.9375 0.799805 8.25 1.2373 8.25H10.2061L6.4248 11.8438C6.14355 12.1562 6.14355 12.625 6.4248 12.9375L7.1123 13.625C7.4248 13.9062 7.89355 13.9062 8.1748 13.625L14.2686 7.53125C14.5498 7.25 14.5498 6.78125 14.2686 6.46875L8.1748 0.40625C7.89355 0.125 7.4248 0.125 7.1123 0.40625L6.4248 1.09375Z"
                          fill="currentColor"
                        />
                      </svg>
                    </ArrowLink>
                  </div>
                )}
              </TwoColumnImageText>
            );
          })}
        </div>
      </SlimContainer>
    </Section>
  );
};

export default Work;
