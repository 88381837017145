import * as React from "react"
import styled from "styled-components"
import { H2, H4, IntroPara, AnchorButton } from "../global/Text"
import IconCard from "../IconCard"
import {Section, SlimContainer, FlexRow, FlexColumn, TextCenter} from "../global/Wrappers"
import { mobileSize } from "../../../utils"
import { theme } from "../../../utils/theme"

const StyledSection = styled(Section)`
    @media ${mobileSize} {
        padding: 3.5rem 0 3rem;
    }
`

interface Props {
    heading: string;
    body: string[];
    items: {
        image: string;
        heading: string;
        subheading: string;
        body: string;
    }[];
    ctaText?: string;
    link?: {
        url: string;
        title: string;
    };
}

const ThreeColumnIconCards: React.FC<Props> = ({ heading, body, items, ctaText, link }) => {
    return(
        <StyledSection padding={`7rem 0 3rem`}>
            <SlimContainer>
                <H2 weight={800} margin={`0 0 1rem`}>{heading}</H2>
                {body &&
                    <div>
                        {body.map((paragraph, index) => {
                            return <IntroPara margin={`0 0 2.5rem`}>{paragraph}</IntroPara>
                        })}
                    </div>
                }
                <FlexRow startFlexOn={`tablet`}>
                    {items.map((item, index) => {
                        return(
                            <FlexColumn startFlexOn={`tablet`} width={`33%`} gap={`1.9375rem`}>
                                <IconCard
                                    image={item.image}
                                    heading={item.heading}
                                    subheading={item.subheading}
                                    body={item.body}
                                />
                            </FlexColumn>
                        ) 
                    })}
                </FlexRow>
                {ctaText &&
                    <TextCenter>
                        <H4 weight={700}>{ctaText}</H4>
                    </TextCenter>
                }
                {link &&
                    <TextCenter>
                        <AnchorButton href={link.url} bgColor={theme.SKY_BLUE}>{link.title}</AnchorButton>
                    </TextCenter>
                }
            </SlimContainer>
        </StyledSection>
    )
}

export default ThreeColumnIconCards