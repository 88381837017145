import * as React from 'react';
import styled from 'styled-components';
import PageData from '../components/ridge/content/android.yaml';
import { H2 } from '../components/ridge/global/Text';
import { PageContainer } from '../components/ridge/global/Wrappers';
import Layout from '../components/ridge/Layout';
import {
  Hero,
  ThreeColumnIconCards,
  Work,
  Benefits,
  Reviews,
  Resources,
} from '../components/ridge/sections';
import DarkFooter from '../components/segments/DarkFooter';
import LetsConnectSection from '../components/segments/LetsConnectSection';
import { NavBar } from '../components/segments/NavBar';
import { IndexLayout } from '../layouts';

const PlaceHolder = styled.section`
  padding: 250px 0;
  background-color: lightgrey;
  text-align: center;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
`;

// markup
const android = () => {
  React.useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);
  return (
    <IndexLayout>
      <PageContainer>
        <NavBar invert alwaysOpaque />
        <Hero
          heading={PageData.hero.heading}
          body={PageData.hero.body}
          formHeading={PageData.hero.form.heading}
        />
        <ThreeColumnIconCards
          heading={PageData.services.heading}
          body={PageData.services.body}
          items={PageData.services.iconCards}
          ctaText={PageData.services.ctaText}
          link={PageData.services.link}
        />
        <Work
          heading={PageData.relatedWork.heading}
          body={PageData.relatedWork.body}
          items={PageData.relatedWork.items}
        />
        <Benefits leftCol={PageData.benefits.leftCol} rightCol={PageData.benefits.rightCol} />
        <Reviews heading={PageData.reviews.heading} reviews={PageData.reviews.reviews} />
        <LetsConnectSection/>
        <Resources
          heading={PageData.resources.heading}
          body={PageData.resources.body}
          items={PageData.resources.items}
        />
        <DarkFooter/>
      </PageContainer>
    </IndexLayout>
  );
};

export default android;
