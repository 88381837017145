import * as React from 'react';
import { Helmet } from 'react-helmet';
import { H2, Para } from '../global/Text';
import { Section, SlimContainer, FlexRow, FlexColumn, TextCenter } from '../global/Wrappers';

interface ReviewsProps {
  heading: string;
  reviews: {
    quote: string;
    author: {
      name: string;
      info: string;
    };
    image: string;
  }[];
}

const Reviews: React.FC<ReviewsProps> = ({ heading, reviews }) => {
  return (
    <Section>
      <Helmet>
        <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
      </Helmet>
      <SlimContainer>
        <TextCenter>
          {heading && <H2>{heading}</H2>}
          <div className="clutch-widget" data-nofollow="true" data-url="https://widget.clutch.co" data-widget-type="12" data-height="375" data-clutchcompany-id="1253700"></div>
        </TextCenter>
        {/* {reviews && (
          <FlexRow>
            {reviews.map((review, index) => {
              return (
                <FlexColumn width={`50%`} gap={`2.25rem`}>
                  <ReviewCard image={review.image} quote={review.quote} author={review.author} />
                </FlexColumn>
              );
            })}
          </FlexRow>
        )} */}
      </SlimContainer>
    </Section>
  );
};

export default Reviews;
