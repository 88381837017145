import * as React from 'react';
import styled from 'styled-components';
import { mobileSize } from '../../../utils';
import { H2, H4, IntroPara, Para } from '../global/Text';
import { Section, SlimContainer, FlexRow, FlexColumn } from '../global/Wrappers';

const LeftColWrapper = styled.div`
  margin-bottom: 0;

  @media ${mobileSize} {
    margin-bottom: 3.4375rem;
  }
`;

interface ResourcesProps {
  leftCol: {
    heading: string;
    body: string[];
  };
  rightCol: {
    heading: string;
    body: string[];
    items: {
      heading: string;
      description: string;
    }[];
  };
}

const Benefits: React.FC<ResourcesProps> = ({ leftCol, rightCol }) => {
  return (
    <Section>
      <SlimContainer>
        <FlexRow>
          <FlexColumn width={`40%`}>
            <LeftColWrapper>
              <H2 weight={800}>{leftCol.heading}</H2>
              {leftCol && leftCol.body && (
                <div>
                  {leftCol.body.map((paragraph: string) => {
                    return <IntroPara margin={`0 0 1.5rem`}>{paragraph}</IntroPara>;
                  })}
                </div>
              )}
            </LeftColWrapper>
          </FlexColumn>
          <FlexColumn width={`60%`}>
            <H2 weight={800}>{rightCol.heading}</H2>
            {rightCol && rightCol.body && (
              <div>
                {rightCol.body.map((paragraph: string) => {
                  return <IntroPara margin={`0 0 1.5rem`}>{paragraph}</IntroPara>;
                })}
              </div>
            )}
            <div>
              {rightCol.items.map((item) => {
                return (
                  <div>
                    <H4 weight={700} margin={`0`}>
                      {item.heading}
                    </H4>
                    <Para margin={`0 0 1.5rem`}>{item.description}</Para>
                  </div>
                );
              })}
            </div>
          </FlexColumn>
        </FlexRow>
      </SlimContainer>
    </Section>
  );
};

export default Benefits;
